import React from 'react'
import Navbar from './Navbar'
import About from './About'
import Work from './Work'
import Testimonials from './Testimonials'
import Contact from './Contact'
import Footer from './Footer'

const Home = () => {
  return (
    <div>
        <Navbar />
        <About />
        <Work />
        <Testimonials />
        <Contact />
        <Footer />
    </div>
  )
}

export default Home